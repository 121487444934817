.bm-card {
  position: relative;
  width: 195px;
  height: 218px;
  background-color: rgb(255, 198, 168);
  box-shadow: 10px 10px 10px 1px #000000;
  font-size: 18px;
  font-family: "Comic Sans MS";

  .bm-sign-area {
    height: 64px;
    width: 100%;
  }

  .bm-des-area {
    top: 64px;
    height: 90px;
    width: 100%;
  }

  .bm-but-area {
    top: 154px;
    height: 64px;
    width: 100%;
  }

  .bm-des-title {
    font-weight: bold;
  }

  .bm-card-btn {
    width: 83px;
    height: 30px;
    border-color: rgb(0, 140, 114);
    border-radius: 0.25rem;
    background-color: rgb(0, 140, 114);
    box-shadow: 3px 3px 3px 3px #000000;
    font-size: 1rem;
  }
}

$break-medium: 768px;

.bm-header {
  width: 100%;
  background-color: rgb(183, 215, 216);
  height: 80px;
  padding: 15px;
  box-shadow: 10px 10px 10px 1px #000000;

  @media screen and (max-width: $break-medium) {
    height: 120px;
  }

  .bm-header-title {
    font-family: "Mystery Quest";
    font-size: 36px;

    &:hover {
      cursor: pointer;
    }
  }

  .bm-header-subtitle {
    font-family: "Arial Bold";
    font-size: 14px;
  }
}

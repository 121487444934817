$break-medium: 768px;

.paper-container {
  max-width: 500px;
  margin: auto;
  padding: 16px;
}

.bm-bee-container {
  height: 440px;
  display: none;
  // border: solid 1px red;

  @media screen and (min-width: $break-medium) {
    margin-top: -80px;
    display: block;
  }

  img {
    float: right;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    // border: solid 2px green;
  }
}

.bm-bullet-container {
  // border: solid 2px green;
  margin-top: 50px;
  @media screen and (min-width: $break-medium) {
    margin-top: -100px;
  }
  ul {
    white-space: nowrap;
  }
}

.bm-text-24 {
  font-size: 24px;
}

.bm-card-container {
  padding: 24px;
}
